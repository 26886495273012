import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MicrosoftAuthComponent} from './components/auth/microsoft-auth.component';
import {LoginPageComponent} from './components/login-page/login-page.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren:  () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'mapping',
    loadChildren: () => import('src/app/modules/mapping-screen/mapping-screen.module').then(m => m.MappingScreenModule),
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'auth',
    component: MicrosoftAuthComponent
  },
  {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
