import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function BothOrNoneCategoryValidator(): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const control1 = group.controls.category;
    const control2 = group.controls.categoryValue;
    if (control1.value && control2.value || !control1.value && !control2.value) {
      control2.setErrors(null);
    } else {
      control2.setErrors({bothOrNone: true});
    }
    return;
  };
}

export function BothOrNoneAttachmentMapperValidator(): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const control1 = group.controls.selected;
    const control2 = group.controls.destination;
    if (control1.value && control2.value || !control1.value && !control2.value) {
      control2.setErrors(null);
    } else {
      control2.setErrors({bothOrNone: true});
    }
    return;
  };
}

