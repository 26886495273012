<section id="csv-rules-import-statistics">
<button data-type="button-dialog-close" mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">Result of the CSV Import</h2>
<div mat-dialog-content>
  <div>
    <table mat-table [dataSource]="dataSource">
      <!-- id -->
      <ng-container matColumnDef="ruleName">
        <mat-header-cell *matHeaderCellDef><span>Rule name</span></mat-header-cell>
        <mat-cell *matCellDef="let element" data-type="text-rule-name"><span> {{element.ruleName}} </span>
        </mat-cell>
      </ng-container>

      <!-- description -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef><span>Status</span></mat-header-cell>
        <mat-cell *matCellDef="let element" data-type="text-rule-status">
          <mat-icon *ngIf="element.error" class="red">error</mat-icon>
          <mat-icon *ngIf="!element.error" class="green">check_circle</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- library -->
      <ng-container matColumnDef="error">
        <mat-header-cell *matHeaderCellDef>Error</mat-header-cell>
        <mat-cell *matCellDef="let element" data-type="text-rule-error">
          <span class="red" *ngIf="element.error">  {{element.error}}</span>
          <span *ngIf="!element.error"> - </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns();" data-type="row-rule" [attr.data-label]="row.ruleName"></mat-row>
    </table>
  </div>
</div>
</section>
