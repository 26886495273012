
<h2 mat-dialog-title>{{data.name}}</h2>

<button data-type="button-dialog-close" mat-mini-fab color="primary" class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<div mat-dialog-content id="rule-viewer-content">
  <div class="aoRow aoRow--mGap aoRow--start">
    <section class="aoFill">
      <mat-label class="attribute-label">Rule name</mat-label>
      <p class="attribute" data-type="text-rule-name">{{data.name}}</p>
    </section>
    <section class="aoFill">
      <mat-label class="attribute-label">Description</mat-label>
      <p class="attribute" data-type="text-rule-description">{{data.description}}</p>
    </section>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="!isGoogleProvider" >
    <div class="aoRow aoRow--mGap aoRow--start">
      <section class="aoFill">
        <mat-label class="attribute-label">Outlook folders</mat-label>
        <br/>
        <span class="attribute" *ngFor="let folder of data.labels; last as isLast" data-type="text-rule-folder" [attr.data-label]="folder.name">
          {{folder.name}}
          <span *ngIf="!isLast">, </span>
        </span>
      </section>

      <section class="aoFill">
        <mat-checkbox data-type="checkbox-include-subfolder"
                      [checked]="data.includeSubFolder"
                      [disabled]="true" class="attribute">Include all subfolders and their content</mat-checkbox>
      </section>
    </div>

    <mat-divider></mat-divider>
  </ng-container>
  <div class="aoRow aoRow--mGap aoRow--start">
    <section class="aoFill">
      <mat-label class="attribute-label">Library</mat-label>
      <p class="attribute" data-type="text-rule-library">{{data.library.name}}</p>
    </section>
    <section class="aoFill" *ngIf="showDocumentClass">
      <mat-label class="attribute-label">Document class</mat-label>
      <p class="attribute" data-type="text-rule-class">{{data.documentType.name}}</p>
    </section>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="data.category">
    <div class="aoRow aoRow--mGap aoRow--start">
      <section class="aoFill">
        <mat-label class="attribute-label">Category</mat-label>
        <p class="attribute" data-type="text-rule-category">{{data.category.displayName}}</p>
      </section>
      <section class="aoFill">
        <mat-label class="attribute-label">Category value</mat-label>
        <p class="attribute" data-type="text-rule-category-value">{{data.categoryValue.name}}</p>
      </section>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="isGoogleProvider">
    <div class="aoRow">
      <section class="aoFill">
        <mat-label class="attribute-label">Gmail labels</mat-label>
        <br/>
        <p class="label" *ngFor="let label of data.labels"
           data-type="text-rule-label" [attr.data-label]="label.name">{{label.name}}</p>
      </section>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <section mat-dialog-actions align="end">
    <button data-type="button-edit-rule" mat-raised-button color="primary" class="fw-500" (click)="edit()">EDIT</button>
  </section>
</div>

