<section class="generic-dialog">
<header>
  <h1 mat-dialog-title data-type="text-dialog-title">{{title}}</h1>
</header>
<div mat-dialog-content>
  <p class="{{type}}" data-type="text-dialog-message">{{message}}</p>
  <p data-type="text-dialog-description">{{description}}</p>
</div>
<div mat-dialog-actions [align]="'right'">
  <button *ngIf="isChoiceBased()" data-type="button-dialog-cancel" mat-button mat-dialog-close [mat-dialog-close]="false" class="grey">Cancel</button>
  <button data-type="button-dialog-ok" mat-button mat-dialog-close color="primary" [mat-dialog-close]="true">OK</button>
</div>
</section>
