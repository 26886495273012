import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-report-detail-column',
  templateUrl: './details-column.component.html',
  styleUrls: ['./details-column.component.scss'],
})
export class DetailsColumnComponent {

  @Input() messageExportJob: any;
  @Input() isRuleExportDetail = false;
}
