import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {Rule} from '../../../../models/rule.model';
import {RuleService} from '../../../../services/rule.service';
import {DialogService} from '../../../../services/dialog.service';
import {RuleCreatorEditorComponent} from '../creator-editor/rule-creator-editor.component';
import {RuleViewerComponent} from '../rule-viewer/rule-viewer.component';
import {
  CsvRulesImportStatisticsComponent
} from '../../../../components/csv-rules-import-statistics/csv-rules-import-statistics.component';
import {AuthService} from '../../../../services/auth.service';
import {AuthProvider} from '../../../../constants/auth-provider-types';
import {DialogType} from '../../../../constants/dialog-types';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, AfterViewInit, OnDestroy {

  public searchValueSubject: Subject<string> = new Subject<string>();
  public searchClearSubject: Subject<boolean> = new Subject();
  public dataSource = new MatTableDataSource<Rule>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public isMicrosoft = this.authService.authProvider === AuthProvider.MICROSOFT;
  public isGoogle = this.authService.isGoogleProvider();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columns = [
    {name: 'name', show: true},
    {name: 'description', show: true},
    {name: 'labels', show: true},
    {name: 'libraryId', show: true},
    {name: 'documentType', show: true},
    {name: 'actions', show: true},
  ];

  constructor(
    private ruleService: RuleService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadRules();
    this.searchInit();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.searchValueSubject.unsubscribe();
  }

  openCreatorEditor(rule?: Rule): void {
    this.dialog.open(RuleCreatorEditorComponent, {
      data: rule,
      minWidth: '700px',
      panelClass: 'highlighted-dialog'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.loadRules();
      }
    });
  }

  view(rule: Rule): void {
    this.dialog.open(RuleViewerComponent, {
      data: rule,
      minWidth: '700px',
      panelClass: 'highlighted-dialog'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.openCreatorEditor(rule);
      }
    });
  }

  create(): void {
    this.openCreatorEditor();
  }

  refresh(): void {
    this.loadRules();
  }

  loadRules(): void {
    this.loadingSubject.next(true);
    this.ruleService.list().subscribe((resp) => {
      this.loadingSubject.next(false);
      this.dataSource.data = resp || [];
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(error.error.error.message);
    });
  }

  edit(rule: Rule): void {
    this.openCreatorEditor(rule);
  }

  confirmDelete(rule: Rule): void {
    this.dialogService.open({
      message: 'Do you really want to delete the rule ' + rule.name + '?',
      title: 'Confirmation',
      type: DialogType.PLAIN,
    })
      .afterClosed().subscribe((choice) => {
      if (choice) {
        this.delete(rule.id);
      }
    });
  }

  delete(ruleId: string): void {
    this.loadingSubject.next(true);
    this.ruleService.delete(ruleId).subscribe(() => {
      this.loadingSubject.next(false);
      this.loadRules();
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(error.error.error.message);
    });
  }

  importCsv(event, fileInput: HTMLInputElement): void {
    const file = event.target.files[0];
    const fileReader: FileReader = new FileReader();

    fileReader.readAsText(file);
    fileReader.onload = () => {
      const content = fileReader.result;
      fileInput.value = '';
      this.loadingSubject.next(true);
      this.ruleService.importCsv(content).subscribe((resp) => {
        this.loadingSubject.next(false);
        this.dialog.open(CsvRulesImportStatisticsComponent, {
          width: '70vw',
          data: resp,
          panelClass: 'csv-rules-import-statistics'
        }).afterClosed().subscribe(() => {
          this.loadRules();
        });
      }, (error) => {
        this.dialogService.error(error.error.error.message);
        this.loadingSubject.next(false);
      });
    };
  }

  public downloadCsv(): void {
    let data: string;
    let filename: string;
    let link: HTMLElement;

    let csv = this.ruleService.convertArrayOfObjectsToCSV({
      data: this.dataSource.data
    });
    if (csv == null) {
      return;
    }

    const timestamp = new Date().getTime();
    filename = timestamp + '_' + 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  }


  searchInit(): void {
    this.dataSource.filterPredicate = this.filterPredicate;
    this.searchValueSubject.subscribe((value) => {
      this.dataSource.filter = value.trim().toLowerCase();
      this.cdr.detectChanges();
    });
  }

  private filterPredicate(rule: Rule, filter: string): boolean {
    const ruleName = rule.name.toLowerCase();
    const searchWords = filter.split(' ');

    return searchWords.every(word => ruleName.includes(word));
  }

  displayedColumns(): string[] {
    return this.columns.filter(column => column.show).map(column => column.name);
  }

  displayLabelList(rule: Rule): string {
    return (rule.labels as { name: string }[]).map(a => a.name).toString();
  }

}
